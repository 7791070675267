.ImagePreview {
  width: 100%;
  flex: auto;
  overflow-y: overlay;
}

/*-------------------*/

.ImagePreview_Grid {
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  width: 100%;
  background-color: #222;
  overflow-y: overlay;
}

.ImagePreview_Grid--TwoColumn {
  grid-template-columns: 1fr 1fr;
}

/*-------------------*/

.ImagePreviewItem {
  background-color: #222;
  display: flex;
  padding: 2%;
  margin-bottom: 5px;
  overflow: hidden;
}

.ImagePreviewItem_Content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.ImagePreviewItem--portrait {
    flex-direction: row-reverse;
    div.ImagePreviewItem_Container {
      margin: 0px;
      width: 50%;
      height: 80%;
    }
    div.ImagePreviewItem_Text {
      margin-right: 1em;
      writing-mode: vertical-rl;
    }
  }
  &.ImagePreviewItem--landscape {
    flex-direction: column;
    div.ImagePreview_Container {
      margin: 0px;
      width: 80%;
      height: 200px;
    }
    div.ImagePreviewItem_Text {
      margin-top: 1em;
    }
  }
}

.ImagePreviewItem_Text {
  color: white;
}

.ImagePreviewItem_Container {
  background-color: black;
  border-color: white;
  border-style: solid;
  overflow: hidden;
  box-sizing: content-box;
  margin: 0px;
  position: relative;
}

.ImagePreviewItem_Container_Image {
  position: absolute;
}
