/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

 @import '~react-splitter-layout/lib/index.css';
 @import '~react-image-crop/dist/ReactCrop.css';
 @import '~react-essentialrect/dist/essentialrect-img.css';
 
 html,
 body,
 div {
   box-sizing: border-box;
 }
 
 html,
 body,
 #root {
   height: 100%;
   width: 100%;
   margin: 0px;
 }
 
 body {
   position: relative;
   background: white;
   font-family: sans-serif;
 }
 
 button {
   background-color: white;
   padding: 10px 20px;
   border-radius: 10px;
   border: none;
   appearance: none;
   font-size: 1.3rem;
   box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
     0px 18px 88px -4px rgba(24, 39, 75, 0.14);
   transition: transform ease-in 0.1s;
   cursor: pointer;
 }
 
 button:hover {
   transform: scale(1.05);
 }
 
 
 li {
   list-style: none;
 }
 
 a {
   text-decoration: none;
   height: fit-content;
   width: fit-content;
   margin: 10px;
 }
 
 a:hover {
   opacity: 1;
   text-decoration: none;
 }
 
 /* ------------------ */
 
 .left-panel {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
 }
 
 .splitter-layout .layout-pane-primary {
   background-color: #222;
 }
  
/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}