.EditorPanel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* ----------------- */

.EditorControls {
  display: flex;
  justify-items: flex-start;
  width: 100%;
  color: white;
  background-color: black;
  flex-grow: 0;
  align-items: center;
  padding: 0.6em;
}

.EditorControls div:nth-child(2) {
  margin-left: 1em;
}

.EditorControls label {
  display: block;
  font-size: 0.8em;
  margin-bottom: 0.2em;
}

.EditorControls select {
  padding: 0.1em;
  width: 18em;
}

/* ---------------------- */

.EditorBorder {
  padding: 16px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
}

.EditorWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

/* ----------- */

.EditorInfo {
  flex-grow: 0;
  padding: 0.2em;
  background-color: black;
}

.EditorInfoMonitor {
  background-color: white;
  color: black;
  z-index: 300;
  display: inline-block;
  padding: 3px;
  border: 1px solid black;
  cursor: copy;
}

