.controls {
  width: 100%;
  // height: 1.5em;
  color: white;
  flex-grow: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;


  border-bottom: 1px white solid;
  padding: 0.6em;

  .controls-grid-buttons {
    margin-right: 15px;
    display:flex;
  }

  .happy-button .button {
    height: 24px;
    width: 24px;
    &.single {
      display:flex;
      justify-content: center;
      align-items: center;
      .square {
        border-radius: 3px;
        width: 80%;
        height: 80%;
        // box-shadow: inset 0px 0px 5px #c1c1c1;
        // outline: none;
      }
    }
    &.grid {
      display:grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      .square {
        margin: 2px;
      }
    }
  }

}

.happy-button.happy-button-depressed .square {
  background: white;
}
.happy-button.happy-button-not-depressed .square {
  background: #AAA;
}


.happy-button img {
  width: 24px;
  display: block;
  height: 24px;
}
